import { default as _91_46_46_46catchAll_93grXQqvdbJRMeta } from "/vercel/path0/pages/[...catchAll].vue?macro=true";
import { default as indexqOnLrLMUjLMeta } from "/vercel/path0/pages/account/index.vue?macro=true";
import { default as vue_46tab_46defaultxnTNMc1eL9Meta } from "/vercel/path0/pages/account/vue.tab.default.vue?macro=true";
import { default as vue_46tab_46securitygAI5hJomhIMeta } from "/vercel/path0/pages/account/vue.tab.security.vue?macro=true";
import { default as _91token_93m6W0WvrmFlMeta } from "/vercel/path0/pages/confirm/email-[email]/[token].vue?macro=true";
import { default as error9Slgcyzlc1Meta } from "/vercel/path0/pages/confirm/error.vue?macro=true";
import { default as failROasn1sXcuMeta } from "/vercel/path0/pages/confirm/fail.vue?macro=true";
import { default as _91token_93f8oZQYGZ61Meta } from "/vercel/path0/pages/confirm/password/[token].vue?macro=true";
import { default as successJQZ3o4QsR7Meta } from "/vercel/path0/pages/confirm/success.vue?macro=true";
import { default as timeoutpcGeIcAUaxMeta } from "/vercel/path0/pages/confirm/timeout.vue?macro=true";
import { default as _91_46_46_46catchAll_93Fd6Hm9UNleMeta } from "/vercel/path0/pages/eijns-api/[...catchAll].vue?macro=true";
import { default as indexzTZC2cZjadMeta } from "/vercel/path0/pages/eijns-api/index.vue?macro=true";
import { default as _91_46_46_46catchAll_93KEOHKsb1OzMeta } from "/vercel/path0/pages/eijns-api/reference/[...catchAll].vue?macro=true";
import { default as defaulthxpFpsMqVaMeta } from "/vercel/path0/pages/eijns/default.vue?macro=true";
import { default as index7oCky0wQJFMeta } from "/vercel/path0/pages/eijns/index.vue?macro=true";
import { default as helpgTNWcaY3gjMeta } from "/vercel/path0/pages/help.vue?macro=true";
import { default as home_45chartUiTnThcwDqMeta } from "/vercel/path0/pages/home-chart.vue?macro=true";
import { default as homeaAKJ71q3qIMeta } from "/vercel/path0/pages/home.vue?macro=true";
import { default as _91_46_46_46uuid_93XwTZ6f9Lh6Meta } from "/vercel/path0/pages/log/[...uuid].vue?macro=true";
import { default as errorK0tB7HhcNvMeta } from "/vercel/path0/pages/login/error.vue?macro=true";
import { default as indexoOBDY93e9EMeta } from "/vercel/path0/pages/login/index.vue?macro=true";
import { default as nextqDOoOoyP15Meta } from "/vercel/path0/pages/login/next.vue?macro=true";
import { default as logoutBqDDZXgXsyMeta } from "/vercel/path0/pages/logout.vue?macro=true";
import { default as _91uuid_93eBUZZ0MgAAMeta } from "/vercel/path0/pages/messages/[uuid].vue?macro=true";
import { default as indexpt683rA6b7Meta } from "/vercel/path0/pages/messages/index.vue?macro=true";
import { default as _91uuid_93_32_40Kopie_41NZeZeb4o2PMeta } from "/vercel/path0/pages/notification/[uuid] (Kopie).vue?macro=true";
import { default as _91uuid_93kN2iVCjrUoMeta } from "/vercel/path0/pages/notification/[uuid].vue?macro=true";
import { default as _91uuid_93_46vue_46tab_46default5CMiUix7fBMeta } from "/vercel/path0/pages/notification/[uuid].vue.tab.default.vue?macro=true";
import { default as _91uuid_93_46vue_46tab_46emailBuw19ckZSbMeta } from "/vercel/path0/pages/notification/[uuid].vue.tab.email.vue?macro=true";
import { default as _91uuid_93_46vue_46tab_46smsBWxSiv5yaDMeta } from "/vercel/path0/pages/notification/[uuid].vue.tab.sms.vue?macro=true";
import { default as indexDao7PpmqbdMeta } from "/vercel/path0/pages/notification/index.vue?macro=true";
import { default as testVda5zT0FWgMeta } from "/vercel/path0/pages/notification/test.vue?macro=true";
import { default as _91token_933YK0KfQONNMeta } from "/vercel/path0/pages/password/[token].vue?macro=true";
import { default as resetProgressO6f6GCydKjMeta } from "/vercel/path0/pages/password/resetProgress.vue?macro=true";
import { default as _91uuid_93zsKmTyL8zhMeta } from "/vercel/path0/pages/skill/[uuid].vue?macro=true";
import { default as indexiG0K9uLFA5Meta } from "/vercel/path0/pages/skill/index.vue?macro=true";
import { default as indexnpJzOswtosMeta } from "/vercel/path0/pages/system/index.vue?macro=true";
import { default as setupR7mHrYyEfdMeta } from "/vercel/path0/pages/system/setup.vue?macro=true";
import { default as setup_46vue_46tab_46datesSG0bajX2ODMeta } from "/vercel/path0/pages/system/setup.vue.tab.dates.vue?macro=true";
import { default as setup_46vue_46tab_46defaultbmiIg120ZUMeta } from "/vercel/path0/pages/system/setup.vue.tab.default.vue?macro=true";
import { default as setup_46vue_46tab_46editorXWDmhm4PytMeta } from "/vercel/path0/pages/system/setup.vue.tab.editor.vue?macro=true";
import { default as setup_46vue_46tab_46structurebQ67FPyY8QMeta } from "/vercel/path0/pages/system/setup.vue.tab.structure.vue?macro=true";
import { default as testlK5QpQxp8eMeta } from "/vercel/path0/pages/test.vue?macro=true";
import { default as _91uuid_93ong0DADVbpMeta } from "/vercel/path0/pages/user/[uuid].vue?macro=true";
import { default as _91uuid_93_46vue_46tab_46addressmSv0lLgNkWMeta } from "/vercel/path0/pages/user/[uuid].vue.tab.address.vue?macro=true";
import { default as _91uuid_93_46vue_46tab_46aniversariesKGRZWlXGuxMeta } from "/vercel/path0/pages/user/[uuid].vue.tab.aniversaries.vue?macro=true";
import { default as _91uuid_93_46vue_46tab_46credentialsjaLD0aj6diMeta } from "/vercel/path0/pages/user/[uuid].vue.tab.credentials.vue?macro=true";
import { default as _91uuid_93_46vue_46tab_46defaultRpcuoklOxlMeta } from "/vercel/path0/pages/user/[uuid].vue.tab.default.vue?macro=true";
import { default as _91uuid_93_46vue_46tab_46emergencymqYpHFSzGfMeta } from "/vercel/path0/pages/user/[uuid].vue.tab.emergency.vue?macro=true";
import { default as _91uuid_93_46vue_46tab_46personaliEWhabu2s2Meta } from "/vercel/path0/pages/user/[uuid].vue.tab.personal.vue?macro=true";
import { default as indexk3QLtbGbUrMeta } from "/vercel/path0/pages/user/index.vue?macro=true";
import { default as _91token_93YcqAktQC1xMeta } from "/vercel/path0/pages/verify/[token].vue?macro=true";
import { default as _91token_93Xk438UaIMMMeta } from "/vercel/path0/pages/verify/email/[token].vue?macro=true";
import { default as errorvaeyJgPyjfMeta } from "/vercel/path0/pages/verify/email/error.vue?macro=true";
import { default as nextHu1rLMCdOjMeta } from "/vercel/path0/pages/verify/email/next.vue?macro=true";
import { default as okGAlRPMZfiOMeta } from "/vercel/path0/pages/verify/email/ok.vue?macro=true";
import { default as successybKaP7BItPMeta } from "/vercel/path0/pages/verify/email/success.vue?macro=true";
import { default as _91token_93vsjXZDOJV1Meta } from "/vercel/path0/pages/verify/password~aksdhbfhsadjfhasd/[token].vue?macro=true";
import { default as errorfiSWPxXK4sMeta } from "/vercel/path0/pages/verify/phone/error.vue?macro=true";
import { default as indexV8qBsrHGLOMeta } from "/vercel/path0/pages/verify/phone/index.vue?macro=true";
import { default as ok3P1frUN0bKMeta } from "/vercel/path0/pages/verify/phone/ok.vue?macro=true";
import { default as homeq5pUrtvVhTMeta } from "/vercel/path0/modules/questionnaire/pages/home.vue?macro=true";
import { default as homeMFUsK93Eu7Meta } from "/vercel/path0/modules/rantomat/pages/home.vue?macro=true";
import { default as indexv7fi8ke6W6Meta } from "/vercel/path0/modules/rantomat/pages/[employeeId]/index.vue?macro=true";
import { default as indexWGVs58Hz2EMeta } from "/vercel/path0/modules/rantomat/pages/[employeeId]/[date]/index.vue?macro=true";
import { default as homeOC7dKLSCyHMeta } from "/vercel/path0/modules/vacationeeze/pages/home.vue?macro=true";
export default [
  {
    name: "catchAll",
    path: "/:catchAll(.*)*",
    meta: _91_46_46_46catchAll_93grXQqvdbJRMeta || {},
    component: () => import("/vercel/path0/pages/[...catchAll].vue")
  },
  {
    name: "account",
    path: "/account",
    component: () => import("/vercel/path0/pages/account/index.vue")
  },
  {
    name: "account-vue.tab.default",
    path: "/account/vue.tab.default",
    component: () => import("/vercel/path0/pages/account/vue.tab.default.vue")
  },
  {
    name: "account-vue.tab.security",
    path: "/account/vue.tab.security",
    component: () => import("/vercel/path0/pages/account/vue.tab.security.vue")
  },
  {
    name: "confirm-email-email-token",
    path: "/confirm/email-:email()/:token()",
    meta: _91token_93m6W0WvrmFlMeta || {},
    component: () => import("/vercel/path0/pages/confirm/email-[email]/[token].vue")
  },
  {
    name: "confirm-error",
    path: "/confirm/error",
    meta: error9Slgcyzlc1Meta || {},
    component: () => import("/vercel/path0/pages/confirm/error.vue")
  },
  {
    name: "confirm-fail",
    path: "/confirm/fail",
    meta: failROasn1sXcuMeta || {},
    component: () => import("/vercel/path0/pages/confirm/fail.vue")
  },
  {
    name: "confirm-password-token",
    path: "/confirm/password/:token()",
    meta: _91token_93f8oZQYGZ61Meta || {},
    component: () => import("/vercel/path0/pages/confirm/password/[token].vue")
  },
  {
    name: "confirm-success",
    path: "/confirm/success",
    meta: successJQZ3o4QsR7Meta || {},
    component: () => import("/vercel/path0/pages/confirm/success.vue")
  },
  {
    name: "confirm-timeout",
    path: "/confirm/timeout",
    meta: timeoutpcGeIcAUaxMeta || {},
    component: () => import("/vercel/path0/pages/confirm/timeout.vue")
  },
  {
    name: "eijns-api-catchAll",
    path: "/eijns-api/:catchAll(.*)*",
    component: () => import("/vercel/path0/pages/eijns-api/[...catchAll].vue")
  },
  {
    name: "eijns-api",
    path: "/eijns-api",
    component: () => import("/vercel/path0/pages/eijns-api/index.vue")
  },
  {
    name: "eijns-api-reference-catchAll",
    path: "/eijns-api/reference/:catchAll(.*)*",
    component: () => import("/vercel/path0/pages/eijns-api/reference/[...catchAll].vue")
  },
  {
    name: "eijns-default",
    path: "/eijns/default",
    component: () => import("/vercel/path0/pages/eijns/default.vue")
  },
  {
    name: "eijns",
    path: "/eijns",
    component: () => import("/vercel/path0/pages/eijns/index.vue")
  },
  {
    name: "help",
    path: "/help",
    meta: helpgTNWcaY3gjMeta || {},
    component: () => import("/vercel/path0/pages/help.vue")
  },
  {
    name: "home-chart",
    path: "/home-chart",
    component: () => import("/vercel/path0/pages/home-chart.vue")
  },
  {
    name: "home",
    path: "/home",
    component: () => import("/vercel/path0/pages/home.vue")
  },
  {
    name: "log-uuid",
    path: "/log/:uuid(.*)*",
    component: () => import("/vercel/path0/pages/log/[...uuid].vue")
  },
  {
    name: "login-error",
    path: "/login/error",
    meta: errorK0tB7HhcNvMeta || {},
    component: () => import("/vercel/path0/pages/login/error.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: indexoOBDY93e9EMeta || {},
    component: () => import("/vercel/path0/pages/login/index.vue")
  },
  {
    name: "login-next",
    path: "/login/next",
    meta: nextqDOoOoyP15Meta || {},
    component: () => import("/vercel/path0/pages/login/next.vue")
  },
  {
    name: "logout",
    path: "/logout",
    meta: logoutBqDDZXgXsyMeta || {},
    component: () => import("/vercel/path0/pages/logout.vue")
  },
  {
    name: "messages-uuid",
    path: "/messages/:uuid()",
    component: () => import("/vercel/path0/pages/messages/[uuid].vue")
  },
  {
    name: "messages",
    path: "/messages",
    component: () => import("/vercel/path0/pages/messages/index.vue")
  },
  {
    name: "notification-uuid ",
    path: "/notification/:uuid()%20",
    component: () => import("/vercel/path0/pages/notification/[uuid] (Kopie).vue")
  },
  {
    name: "notification-uuid",
    path: "/notification/:uuid()",
    component: () => import("/vercel/path0/pages/notification/[uuid].vue")
  },
  {
    name: "notification-uuid.vue.tab.default",
    path: "/notification/:uuid().vue.tab.default",
    component: () => import("/vercel/path0/pages/notification/[uuid].vue.tab.default.vue")
  },
  {
    name: "notification-uuid.vue.tab.email",
    path: "/notification/:uuid().vue.tab.email",
    component: () => import("/vercel/path0/pages/notification/[uuid].vue.tab.email.vue")
  },
  {
    name: "notification-uuid.vue.tab.sms",
    path: "/notification/:uuid().vue.tab.sms",
    component: () => import("/vercel/path0/pages/notification/[uuid].vue.tab.sms.vue")
  },
  {
    name: "notification",
    path: "/notification",
    component: () => import("/vercel/path0/pages/notification/index.vue")
  },
  {
    name: "notification-test",
    path: "/notification/test",
    component: () => import("/vercel/path0/pages/notification/test.vue")
  },
  {
    name: "password-token",
    path: "/password/:token()",
    meta: _91token_933YK0KfQONNMeta || {},
    component: () => import("/vercel/path0/pages/password/[token].vue")
  },
  {
    name: "password-resetProgress",
    path: "/password/resetProgress",
    meta: resetProgressO6f6GCydKjMeta || {},
    component: () => import("/vercel/path0/pages/password/resetProgress.vue")
  },
  {
    name: "skill-uuid",
    path: "/skill/:uuid()",
    component: () => import("/vercel/path0/pages/skill/[uuid].vue")
  },
  {
    name: "skill",
    path: "/skill",
    component: () => import("/vercel/path0/pages/skill/index.vue")
  },
  {
    name: "system",
    path: "/system",
    component: () => import("/vercel/path0/pages/system/index.vue")
  },
  {
    name: "system-setup",
    path: "/system/setup",
    component: () => import("/vercel/path0/pages/system/setup.vue")
  },
  {
    name: "system-setup.vue.tab.dates",
    path: "/system/setup.vue.tab.dates",
    component: () => import("/vercel/path0/pages/system/setup.vue.tab.dates.vue")
  },
  {
    name: "system-setup.vue.tab.default",
    path: "/system/setup.vue.tab.default",
    component: () => import("/vercel/path0/pages/system/setup.vue.tab.default.vue")
  },
  {
    name: "system-setup.vue.tab.editor",
    path: "/system/setup.vue.tab.editor",
    component: () => import("/vercel/path0/pages/system/setup.vue.tab.editor.vue")
  },
  {
    name: "system-setup.vue.tab.structure",
    path: "/system/setup.vue.tab.structure",
    component: () => import("/vercel/path0/pages/system/setup.vue.tab.structure.vue")
  },
  {
    name: "test",
    path: "/test",
    component: () => import("/vercel/path0/pages/test.vue")
  },
  {
    name: "user-uuid",
    path: "/user/:uuid()",
    component: () => import("/vercel/path0/pages/user/[uuid].vue")
  },
  {
    name: "user-uuid.vue.tab.address",
    path: "/user/:uuid().vue.tab.address",
    component: () => import("/vercel/path0/pages/user/[uuid].vue.tab.address.vue")
  },
  {
    name: "user-uuid.vue.tab.aniversaries",
    path: "/user/:uuid().vue.tab.aniversaries",
    component: () => import("/vercel/path0/pages/user/[uuid].vue.tab.aniversaries.vue")
  },
  {
    name: "user-uuid.vue.tab.credentials",
    path: "/user/:uuid().vue.tab.credentials",
    component: () => import("/vercel/path0/pages/user/[uuid].vue.tab.credentials.vue")
  },
  {
    name: "user-uuid.vue.tab.default",
    path: "/user/:uuid().vue.tab.default",
    component: () => import("/vercel/path0/pages/user/[uuid].vue.tab.default.vue")
  },
  {
    name: "user-uuid.vue.tab.emergency",
    path: "/user/:uuid().vue.tab.emergency",
    component: () => import("/vercel/path0/pages/user/[uuid].vue.tab.emergency.vue")
  },
  {
    name: "user-uuid.vue.tab.personal",
    path: "/user/:uuid().vue.tab.personal",
    component: () => import("/vercel/path0/pages/user/[uuid].vue.tab.personal.vue")
  },
  {
    name: "user",
    path: "/user",
    component: () => import("/vercel/path0/pages/user/index.vue")
  },
  {
    name: "verify-token",
    path: "/verify/:token()",
    meta: _91token_93YcqAktQC1xMeta || {},
    component: () => import("/vercel/path0/pages/verify/[token].vue")
  },
  {
    name: "verify-email-token",
    path: "/verify/email/:token()",
    meta: _91token_93Xk438UaIMMMeta || {},
    component: () => import("/vercel/path0/pages/verify/email/[token].vue")
  },
  {
    name: "verify-email-error",
    path: "/verify/email/error",
    meta: errorvaeyJgPyjfMeta || {},
    component: () => import("/vercel/path0/pages/verify/email/error.vue")
  },
  {
    name: "verify-email-next",
    path: "/verify/email/next",
    meta: nextHu1rLMCdOjMeta || {},
    component: () => import("/vercel/path0/pages/verify/email/next.vue")
  },
  {
    name: "verify-email-ok",
    path: "/verify/email/ok",
    meta: okGAlRPMZfiOMeta || {},
    component: () => import("/vercel/path0/pages/verify/email/ok.vue")
  },
  {
    name: "verify-email-success",
    path: "/verify/email/success",
    meta: successybKaP7BItPMeta || {},
    component: () => import("/vercel/path0/pages/verify/email/success.vue")
  },
  {
    name: "verify-password~aksdhbfhsadjfhasd-token",
    path: "/verify/password~aksdhbfhsadjfhasd/:token()",
    meta: _91token_93vsjXZDOJV1Meta || {},
    component: () => import("/vercel/path0/pages/verify/password~aksdhbfhsadjfhasd/[token].vue")
  },
  {
    name: "verify-phone-error",
    path: "/verify/phone/error",
    meta: errorfiSWPxXK4sMeta || {},
    component: () => import("/vercel/path0/pages/verify/phone/error.vue")
  },
  {
    name: "verify-phone",
    path: "/verify/phone",
    meta: indexV8qBsrHGLOMeta || {},
    component: () => import("/vercel/path0/pages/verify/phone/index.vue")
  },
  {
    name: "verify-phone-ok",
    path: "/verify/phone/ok",
    meta: ok3P1frUN0bKMeta || {},
    component: () => import("/vercel/path0/pages/verify/phone/ok.vue")
  },
  {
    name: "questionnaire",
    path: "/questionnaire",
    component: () => import("/vercel/path0/modules/questionnaire/pages/home.vue")
  },
  {
    name: "rantomat",
    path: "/rantomat/",
    component: () => import("/vercel/path0/modules/rantomat/pages/home.vue")
  },
  {
    name: "rantomatEmployeeDays",
    path: "/rantomat/:employeeId?/",
    component: () => import("/vercel/path0/modules/rantomat/pages/[employeeId]/index.vue")
  },
  {
    name: "rantomatEmployeeDayDetail",
    path: "/rantomat/:employeeId?/:date?/",
    component: () => import("/vercel/path0/modules/rantomat/pages/[employeeId]/[date]/index.vue")
  },
  {
    name: "vacationeeze",
    path: "/vacationeeze",
    component: () => import("/vercel/path0/modules/vacationeeze/pages/home.vue")
  }
]