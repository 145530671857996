import _ from 'lodash'

/**
 * Find an element by its _id and get all _ids of its sub-elements.
 * @param {string} id - The _id of the element to find.
 * @param {Array} structure - The structure to search through.
 * @returns {Object|null} - The element with the matching _id and all _ids of its sub-elements.
 */
export const findStructure = (id, structure) => {
    try {
        // Hilfsfunktion, um alle untergeordneten Elemente rekursiv zu finden
        const getAllSubElements = (structure) => {
            let elements = [];
            for (const element of structure) {
                // Füge das aktuelle Element hinzu und suche rekursiv nach weiteren untergeordneten Elementen
                elements.push({
                    ...element,
                    structure: undefined, // Entferne die Struktur, um zirkuläre Referenzen zu vermeiden
                    subElements: element.structure ? getAllSubElements(element.structure) : []
                });
            }
            return elements;
        };

        const getAllSubElementIds = (structure) => {
            let ids = [];
            for (const element of structure) {
                ids.push(element._id.toString()); // Füge die _id des aktuellen Elements hinzu
                // Wenn das Element eine untergeordnete Struktur hat, füge die IDs rekursiv hinzu
                if (element.structure) {
                    ids = ids.concat(getAllSubElementIds(element.structure));
                }
            }
            return ids;
        };

        // Rekursive Funktion zum Durchsuchen der Struktur
        const findRecursive = (id, structure, path = []) => {
            for (const element of structure) {
                // Konvertiere _id zu String für den Vergleich
                const elementIdStr = element._id.toString();

                // Prüfe, ob die aktuelle _id mit der vorgegebenen id übereinstimmt
                const isMatch = Array.isArray(id)
                    ? id.some(singleId => singleId.toString() === elementIdStr)
                    : id.toString() === elementIdStr;

                if (isMatch) {
                    // Gefundenes Element mit allen untergeordneten Elementen
                    const subElements = [element._id.toString(), ...getAllSubElementIds(element.structure || [])];
                    return { element: {...element, structure: undefined}, subElements, path: [...path, element._id] };
                }

                // Wenn das Element eine verschachtelte Struktur hat, suche rekursiv
                if (element.structure) {
                    const result = findRecursive(id, element.structure, [...path, element._id]);
                    if (result) return result;
                }
            }
            return null;
        };

        // Verarbeite den Fall, dass id ein Array oder ein einzelner Wert sein kann
        if (Array.isArray(id)) {
            // Sammle Ergebnisse für alle ids im Array
            return id.map(singleId => findRecursive(singleId.toString(), structure)).filter(result => result !== null);
        } else {
            // Wenn id kein Array ist, starte die rekursive Suche
            return findRecursive(id.toString(), structure);
        }
    } catch(err) {
        console.error(err)
    }
};

/**
 * Find an element by its _id and get all _ids while supervisor.
 * @param {string} id - The _id of the user to find or true to get all sub items
 * @param {Array} structure - The structure to search through.
 * @returns {Object|null} - The element with the matching _id and all _ids of its sub-elements.
 */
export const findAffiliation = (id, structure) => {
    try {
        let result = [];

        // Wenn die Struktur ein Array ist, durchsuche jedes Element
        if (Array.isArray(structure)) {

            for (const element of structure) {
                // Rekursiver Aufruf für jedes Element in der Struktur
                result = result.concat(findAffiliation(id?.toString(), element));
            }
        } else {

            // Überprüfe, ob die aktuelle Struktur die gesuchte ID in der `supervisor`-Liste hat
            if (structure.supervisor && structure.supervisor.includes(id?.toString())) {
                result.push(structure._id.toString()); // Füge die `_id` der Struktur hinzu
            }

            // Rekursiver Aufruf für die untergeordnete Struktur, falls vorhanden
            if (structure.structure) {
                result = result.concat(findAffiliation(id?.toString(), structure.structure));
            }
        }
        return result;
    } catch(err) {
        console.error(err)
    }
};

export const findSubordinated = (id, structure, affiliation) => {
    try {

        if (id === null && affiliation) {
            const foundAffiliations = findStructure(affiliation, structure)
            return foundAffiliations?.subElements || [];
        }

        const supervisorAffiliations = findAffiliation(id, structure) || []
        const foundAffiliations = [
            ...supervisorAffiliations,
            ...findStructure(supervisorAffiliations, structure)?.[0]?.subElements || []
        ]
        
        return foundAffiliations;
    } catch(err) {
        console.error(err)
    }
};

/**
 * Findet das nächsthöhere Element mit einem Supervisor.
 * @param {string} id - Die _id des zu suchenden Elementes.
 * @param {Array} structure - Die Struktur, in der gesucht wird.
 * @param {Array} path - Der aktuelle Pfad von _ids durch die Struktur.
 * @returns {Object|null} - Das nächsthöhere Element mit einem Supervisor oder null, falls keines gefunden wird.
 */
/*export const findElementWithSupervisor = (id, structure, path = []) => {
    // Wenn keine Struktur vorhanden ist, breche die Suche ab.
    if (!structure) {
        return null;
    }

    for (const element of structure) {
        // Konvertiere die _id zu einem String für den Vergleich.
        const elementIdStr = element._id.toString();

        // Prüfe, ob die aktuelle _id mit der gesuchten _id übereinstimmt.
        if (id === elementIdStr) {
            // Wenn das aktuelle Element ein Supervisor-Feld hat, gib dieses Element zurück.
            if (element.supervisor.length > 0) {
                return { ...element, subElements: path };
            }

            // Wenn kein Supervisor gefunden wird, suche in der nächsten Ebene.
            const parentElement = findElementInPath(structure, path);
            if (parentElement) {
                return findElementWithSupervisor(parentElement._id, structure, path.slice(0, -1));
            }
        }

        // Wenn das Element eine untergeordnete Struktur hat, füge die _id zum Pfad hinzu und durchsuche rekursiv.
        if (element.structure) {
            const result = findElementWithSupervisor(id, element.structure, [...path, element._id]);
            if (result) {
                return result;
            }
        }
    }

    // Wenn das Element mit der gesuchten _id oder ein Supervisor nicht gefunden wird, gib null zurück.
    return null;
};*/

/**
 * Findet das Elternelement eines bestimmten Pfads.
 * @param {Array} structure - Die Struktur, in der gesucht wird.
 * @param {Array} path - Der Pfad von _ids durch die Struktur.
 * @returns {Object|null} - Das Elternelement des Pfads oder null, falls nicht gefunden.
 */
/*export const findElementInPath = (structure, path) => {
    let currentStructure = structure;
    for (const id of path) {
        const element = currentStructure.find(e => e._id.toString() === id.toString());
        if (!element) {
            return null;
        }
        currentStructure = element.structure;
    }
    return currentStructure;
};*/

/**
 * Findet ein Element und alle übergeordneten Elemente bis zum nächsten Supervisor.
 * @param {Array} ids - Die _ids der Elemente, nach denen gesucht wird.
 * @param {Array} structure - Die Struktur, in der gesucht wird.
 * @returns {Array} - Alle _ids vom gefundenen Element bis zum nächsten Supervisor.
 */
/*export const findPathToSupervisor = (ids, structure) => {
    const pathToSupervisor = [];

    const findRecursive = (currentStructure, targetIds, path) => {
        for (const element of currentStructure) {
            const newPath = path.concat(element._id);
            if (targetIds.includes(element._id)) {
                // Das Element wurde gefunden, nun den Pfad zurückverfolgen, um den Supervisor zu finden
                pathToSupervisor.push(newPath);
                return true;    // Stoppt die Suche, da das Element gefunden wurde
            }
            if (element.structure && findRecursive(element.structure, targetIds, newPath)) {
                // Wenn das Element eine Struktur hat, suche rekursiv weiter
                return true;
            }
        }
        return false;
    };

    // Starte die rekursive Suche für jede ID
    ids.forEach(id => findRecursive(structure, [id], []));

    // Nun den Pfad zum nächstgelegenen Supervisor finden
    let supervisorFound = false;
    const supervisorPath = [];

    for (const singlePath of pathToSupervisor) {
        if (supervisorFound) break;    // Wenn ein Supervisor gefunden wurde, abbrechen

        for (let i = singlePath.length - 1; i >= 0; i--) {
            const idToCheck = singlePath[i];
            const element = findElementById(idToCheck, structure);
            if (element && element.supervisor) {
                supervisorPath.push(idToCheck);
                supervisorFound = true;
                break;    // Supervisor gefunden, keine weitere Suche notwendig
            }
        }
    }

    return supervisorPath;
};*/

/**
 * Hilfsfunktion zum Finden eines Elements in der Struktur durch seine _id.
 * @param {string} id - Die _id des Elements.
 * @param {Array} structure - Die Struktur, in der gesucht wird.
 * @returns {Object|null} - Das gefundene Element oder null, wenn kein Element gefunden wird.
 */
/*export const findElementById = (id, structure) => {
    for (const element of structure) {
        if (element._id === id) {
            return element;
        }
        if (element.structure) {
            const found = findElementById(id, element.structure);
            if (found) {
                return found;
            }
        }
    }
    return null;    // Kein Element mit der gegebenen _id gefunden
};*/

/**
 * Findet rekursiv das übergeordnete Strukturelement mit einem Supervisor.
 * @param {string|mongoose.Types.ObjectId} id - Die _id des Elements als String oder ObjectId.
 * @param {Array} structure - Die Struktur, in der gesucht wird.
 * @returns {Object|null} - Das gefundene Strukturelement oder null, wenn keines gefunden wird.
 */
/*export const findStructureWithSupervisor_ = (id, structure) => {
    let currentStructure = structure;

    // Konvertiert die _id in einen String für den Vergleich, wenn es ein ObjectId ist
    const targetId = typeof id === 'string' ? id : id.toString();

    // Rekursive Hilfsfunktion, um durch die Struktur zu navigieren
    const traverseStructure = (currentStructure, targetId) => {
        for (const element of currentStructure) {
            // Konvertiert die _id in einen String für den Vergleich, wenn es ein ObjectId ist
            const elementId = element._id.toString();

            if (elementId === targetId) {
                // Gibt das aktuelle Strukturelement zurück, wenn es die gesuchte _id hat
                return element;
            } else if (element.structure) {
                // Geht weiter die Struktur hinunter
                const result = traverseStructure(element.structure, targetId);
                if (result) {
                    // Wenn das gesuchte Element in einer tieferen Ebene gefunden wurde,
                    // überprüfe, ob das aktuelle Element einen Supervisor hat
                    if (element.supervisor && element.supervisor.length > 0) {
                        // Gibt das aktuelle Element zurück, da es einen Supervisor hat
                        return element;
                    }
                    // Wenn das aktuelle Element keinen Supervisor hat, gehe weiter zurück
                    return result;
                }
            }
        }
        // Wenn das Element nicht gefunden wurde, gehe eine Ebene höher
        return null;
    };

    // Startet die Suche mit der gesamten Struktur und der Ziel-_id
    return traverseStructure(currentStructure, targetId);
};*/

/**
 * Sucht rekursiv nach unten und gibt das erste Element mit einem Supervisor zurück,
 * nachdem ein Element mit der gegebenen `_id` gefunden wurde.
 * @param {string|mongoose.Types.ObjectId} id - Die _id des Elements als String oder ObjectId.
 * @param {Array} structure - Die Struktur, in der gesucht wird.
 * @returns {Object|null} - Das gefundene Strukturelement oder null, wenn keines gefunden wird.
 */
/*export const findFirstStructureWithSupervisor = (id, structure) => {
  // Konvertiert die _id in einen String für den Vergleich, wenn es ein ObjectId ist
  const targetId = typeof id === 'string' ? id : id.toString();

  // Rekursive Hilfsfunktion, um durch die Struktur zu navigieren
  const traverseStructure = (structure, targetId) => {
    for (const element of structure) {
      // Konvertiert die _id in einen String für den Vergleich, wenn es ein ObjectId ist
      const elementId = element._id.toString();

      if (elementId === targetId) {
        // Überprüft, ob das gefundene Element selbst ein Supervisor ist
        if (element.supervisor && element.supervisor.length > 0) {
          return element; // Gibt das gefundene Element zurück
        }
        // Wenn das Element kein Supervisor ist, setze die Suche nicht fort
        return null;
      } else if (element.structure) {
        // Geht weiter die Struktur hinunter
        const found = traverseStructure(element.structure, targetId);
        if (found) {
          // Wenn das Element in einer tieferen Ebene gefunden wurde, gebe es zurück
          return found;
        } else if (element.supervisor && element.supervisor.length > 0) {
          // Wenn das Element nicht das gesuchte ist, aber ein Supervisor, gib es zurück
          return element;
        }
      }
    }
    // Wenn das Element nicht gefunden wurde und kein Supervisor auf dieser Ebene ist
    return null;
  };

  // Startet die Suche mit der gesamten Struktur und der Ziel-_id
  return traverseStructure(structure, targetId);
};*/


/**
 * Rekursive Suche nach oben im Baum, bis ein Element mit einem Supervisor gefunden wird.
 * @param {string|mongoose.Types.ObjectId} id - Die _id des Ziel-Elements als String oder ObjectId.
 * @param {Array} structure - Die Struktur, in der gesucht wird.
 * @param {Array} [path=[]] - Der Pfad, der während der rekursiven Suche nach unten aufgebaut wird.
 * @returns {Object|null} - Das Element, das einen Supervisor enthält, oder null, falls keines gefunden wird.
 */
export const findStructureWithSupervisor = (ids, structure, path = []) => {
    // Konvertiert die _id in einen String für den Vergleich
    // const targetId = id.toString();
    const result = []

    // Funktion zum Durchsuchen der Struktur
    const traverseStructure = (structure, targetId, path) => {
        for (const element of structure) {
            // Fügt das aktuelle Element zum Pfad hinzu
            const newPath = path.concat(element);

            if (element._id.toString() === targetId) {
                // Beginnt die Rückwärtssuche, wenn das Ziel-Element gefunden wird
                return searchUpwardsForSupervisor(newPath);
            }

            // Wenn das Element eine untergeordnete Struktur hat, setzt die Suche rekursiv fort
            if (Array.isArray(element.structure)) {
                const result = traverseStructure(element.structure, targetId, newPath);
                if (result) return result; // Gibt das Ergebnis der Rückwärtssuche zurück
            }
        }
        // Wenn das Ziel-Element nicht gefunden wird, gibt null zurück
        return null;
    };

    // Funktion zum Rückwärtssuchen nach einem Supervisor
    const searchUpwardsForSupervisor = (path) => {
        // Geht den Pfad rückwärts entlang
        for (let i = path.length - 1; i >= 0; i--) {
            const element = path[i];
            if (element.supervisor && element.supervisor.length > 0) {
                // Gibt das erste Element mit einem Supervisor zurück
                return element;
            }
        }
        // Wenn kein Element mit einem Supervisor gefunden wird, gibt null zurück
        return null;
    };

    // Startet die Suche
    for (const id of ids) {
        result.push(traverseStructure(structure, id.toString(), path))
    }

    return result
    // return ids.forEach(id => traverseStructure(structure, id.toString(), path));
    // return traverseStructure(structure, targetId, path);
};




/*export default async (id, structure, lv = 0, path = []) => {

    // Define the recursive function to process the structure
    const getAffiliation = async (id, structure, lv = 0, path) => {
        let result = [];

        // Check if structure is an array-like object
        if (structure && Object.keys(structure)[0] === '0') {

            // Iterate through the structure
            for (let i = 0; i < Object.keys(structure).length; i++) {
                const currentItem = structure[i];

                // Check if current item's id matches the given id
                if ((id === '*' || id === '***' || id.toString() === currentItem._id.toString()) && currentItem.structure) {
                    const itemStructure = {
                        ...currentItem,
                        fullname: [...path, currentItem.name].join(' > '),
                        path: [...path, currentItem.name],
                        color: currentItem.color,
                        icon: currentItem.icon,
                    };

                    // If id is '***', remove the structure property
                    if (id === '***') {
                        delete itemStructure.structure;
                    }

                    result.push(itemStructure);

                    // Recursively process the nested structure
                    const nestedResult = await getAffiliation(id, currentItem.structure, lv + 1, [...path, currentItem.name]);
                    result = [...result, ...nestedResult];
                }
            }
        }

        return result;
    };

    // Execute the function and handle any errors
    try {
        return await getAffiliation(id, structure, lv, path);
    } catch (err) {
        console.error(err);
        throw err; // Rethrow the error after logging
    }
};*/


// Asynchrone Hauptfunktion, um eine bestimmte Affiliation in einer Struktur zu finden
export default async (id, structure, lv = 0, path = []) => {

    // Asynchrone innere Funktion, um durch die Struktur zu iterieren und zu prüfen
    const getAffiliation = async (id, structure, lv = 0, path) => {
        let result = []; // Ergebnis-Array initialisieren
        // Überprüfen, ob eine Struktur existiert und in einem array-ähnlichen Objekt vorliegt
        if(structure && Object.keys(structure)[0] == '0') {
            // Durch alle Elemente der Struktur iterieren
            for(let i = 0; i < Object.keys(structure).length; i++) {
                // Überprüfen, ob die aktuelle ID mit der gesuchten übereinstimmt (nicht * oder ***)
                if(id != '*' && id != '***' && id.toString() == structure[Object.keys(structure)[i]]._id.toString()) {
                    // Falls ja, füge den Namen des gefundenen Elements zum Ergebnis hinzu
                    result.push(structure[Object.keys(structure)[i]].name)
                } 
                // Wenn die ID * oder *** ist oder das aktuelle Element eine Unterstruktur hat
                else if(id == '*' || id == '***' || structure[Object.keys(structure)[i]].structure) {
                    let struct;
                    // Wenn die ID *** ist, erstelle ein Objekt mit vollständigem Namen und Pfad, aber ohne die Unterstruktur
                    if (id === '***') {
                        struct = {
                            ...structure[Object.keys(structure)[i]],
                            fullname: [...path, structure[Object.keys(structure)[i]].name].join(' > '),
                            path: [...path, structure[Object.keys(structure)[i]].name],
                            color: structure[Object.keys(structure)[i]].color,
                            icon: structure[Object.keys(structure)[i]].icon,
                            slug: [...path, structure[Object.keys(structure)[i]].name].map(e => _.snakeCase(e)).join('-'),
                            supervisor: structure[Object.keys(structure)[i]].supervisor,
                        };
                        delete struct.structure;
                    } else {
                        // Ansonsten (bei * oder einer ID) das Element inklusive Unterstruktur zum Ergebnis hinzufügen
                        struct = {
                            id: structure[Object.keys(structure)[i]]._id,
                            name: structure[Object.keys(structure)[i]].name,
                            fullname: [...path, structure[Object.keys(structure)[i]].name].join(' > '),
                            path: [...path, structure[Object.keys(structure)[i]].name],
                            color: structure[Object.keys(structure)[i]].color,
                            icon: structure[Object.keys(structure)[i]].icon,
                            slug: [...path, structure[Object.keys(structure)[i]].name].map(e => _.snakeCase(e)).join('-'),
                            supervisor: structure[Object.keys(structure)[i]].supervisor,
                        };
                    }
                    result.push(struct);

                    // Rekursiver Aufruf der Funktion für eventuell vorhandene Unterstrukturen
                    const nestedResult = await getAffiliation(id, structure[Object.keys(structure)[i]].structure, lv + 1, [...path, structure[Object.keys(structure)[i]].name]);
                    // Zusammenführen des Ergebnisses mit dem der rekursiven Aufrufe
                    result = [...result, ...nestedResult];
                }
            }
        }
        // Das finale Ergebnis zurückgeben
        return result;
    }

    // Versuche, die getAffiliation-Funktion auszuführen und fange Fehler ab
    try {
        return await getAffiliation(id, structure, lv, path);
    } catch(err) {
        console.error(err); // Logge den Fehler
        throw createError(err); // Wirf den Fehler als neu erstellten Fehler weiter
    }
}
